import { KeyCodes } from "./keyCodes";
var KeyboardUtil = (function () {
    function KeyboardUtil() {
    }
    KeyboardUtil.isEnterKey = function (event) {
        if (event.key !== undefined) {
            return event.key === "Enter";
        }
        else if (event.keyCode !== undefined) {
            return event.keyCode === KeyCodes.ENTER;
        }
        return false;
    };
    return KeyboardUtil;
}());
export { KeyboardUtil };
