var VISITOR_PROFILE_URL = "/orion/ajax/signals/";
var VisitorProfile = (function () {
    function VisitorProfile(ajaxService) {
        this.ajaxService = ajaxService;
    }
    VisitorProfile.prototype.postVisitorProfile = function (visitorData) {
        this.ajaxService.postJSONSafe(VISITOR_PROFILE_URL, visitorData).subscribe(function () { });
    };
    return VisitorProfile;
}());
export { VisitorProfile };
