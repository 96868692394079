var ViewportBreakpoint;
(function (ViewportBreakpoint) {
    ViewportBreakpoint[ViewportBreakpoint["XS"] = 0] = "XS";
    ViewportBreakpoint[ViewportBreakpoint["SM"] = 576] = "SM";
    ViewportBreakpoint[ViewportBreakpoint["MD"] = 768] = "MD";
    ViewportBreakpoint[ViewportBreakpoint["BOOTSTRAP_LG"] = 992] = "BOOTSTRAP_LG";
    ViewportBreakpoint[ViewportBreakpoint["LG"] = 1024] = "LG";
    ViewportBreakpoint[ViewportBreakpoint["XL"] = 1360] = "XL";
})(ViewportBreakpoint || (ViewportBreakpoint = {}));
export { ViewportBreakpoint };
