var DatepickerUtils = (function () {
    function DatepickerUtils() {
    }
    DatepickerUtils.parseYMDstring = function (dateStr) {
        if (dateStr.match(/^\d{8}$/)) {
            var year_1 = Number(dateStr.substring(0, 4));
            var month_1 = Number(dateStr.substring(4, 6)) - 1;
            var day_1 = Number(dateStr.substring(6, 8));
            return new Date(year_1, month_1, day_1);
        }
        var dateArray = dateStr.split("-");
        var year = Number(dateArray[0]);
        var month = Number(dateArray[1]) - 1;
        var day = Number(dateArray[2]);
        return new Date(year, month, day);
    };
    DatepickerUtils.formatDateToYMDString = function (date) {
        if (!date) {
            return "";
        }
        var year = String(date.getFullYear());
        var month = String(date.getMonth() + 1);
        var dayOfMonth = String(date.getDate());
        var monthPadding = (month.length < 2) ? "0" : "";
        var dayOfMonthPadding = (dayOfMonth.length < 2) ? "0" : "";
        return year + "-" + monthPadding + month + "-" + dayOfMonthPadding + dayOfMonth;
    };
    DatepickerUtils.getDateFormatForLocale = function (locale) {
        var formatStringFromMap = this.dateFormatMap[locale];
        if (formatStringFromMap !== undefined) {
            return formatStringFromMap;
        }
        return this.dateFormatMap["default"];
    };
    DatepickerUtils.dateStringGreaterThanEqualToToday = function (dateStr) {
        var date = DatepickerUtils.parseYMDstring(dateStr);
        if (date) {
            return this.dateGreaterThanOrEqualToToday(date);
        }
        return false;
    };
    DatepickerUtils.dateGreaterThanOrEqualToToday = function (date) {
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        return date >= today;
    };
    DatepickerUtils.datesEqual = function (date1, date2) {
        return date1.getTime() === date2.getTime();
    };
    DatepickerUtils.closeOtherFilterDropdowns = function () {
        $(".dropdown.show [data-toggle='dropdown']").dropdown("toggle");
    };
    DatepickerUtils.formatISODateFromString = function (stringDate) {
        if (stringDate) {
            var unFormattedDate = new Date(stringDate);
            var dd = unFormattedDate.getDate().toString();
            var mm = (unFormattedDate.getMonth() + 1).toString();
            if (unFormattedDate.getDate() < 10) {
                dd = "0" + dd;
            }
            if ((unFormattedDate.getMonth() + 1) < 10) {
                mm = "0" + mm;
            }
            return unFormattedDate.getFullYear() + "-" + mm + "-" + dd;
        }
        else {
            return null;
        }
    };
    DatepickerUtils.toggleDatepickerOpenStatus = function (isOpen) {
        $("body").toggleClass(DatepickerUtils.DATEPICKER_OPEN_CLASS, isOpen);
    };
    DatepickerUtils.DATEPICKER_OPEN_CLASS = "date-picker-opened";
    DatepickerUtils.dateFormatMap = {
        ar: "dddd، d mmmm، yyyy",
        ar_AE: "dddd، d mmmm، yyyy",
        ar_BH: "dddd، d mmmm، yyyy",
        ar_DZ: "dddd، d mmmm، yyyy",
        ar_EG: "dddd، d mmmm، yyyy",
        ar_IQ: "dddd، d mmmm، yyyy",
        ar_JO: "dddd، d mmmm، yyyy",
        ar_KW: "dddd، d mmmm، yyyy",
        ar_LB: "dddd، d mmmm، yyyy",
        ar_LY: "dddd، d mmmm، yyyy",
        ar_MA: "dddd، d mmmm، yyyy",
        ar_OM: "dddd، d mmmm، yyyy",
        ar_QA: "dddd، d mmmm، yyyy",
        ar_SA: "dddd، d mmmm، y G",
        ar_SD: "dddd، d mmmm، yyyy",
        ar_SY: "dddd، d mmmm، yyyy",
        ar_TN: "dddd، d mmmm، yyyy",
        ar_YE: "dddd، d mmmm، yyyy",
        be: "dddd, d mmmm yyyy !г.",
        be_BY: "dddd, d mmmm yyyy !г.",
        bg: "dddd, d mmmm yyyy !г.",
        bg_BG: "dddd, d mmmm yyyy !г.",
        ca: "dddd, d mmmm !de yyyy",
        ca_ES: "dddd, d mmmm !de yyyy",
        cs: "dddd d. mmmm yyyy",
        cs_CZ: "dddd d. mmmm yyyy",
        da: "dddd !den d. mmmm yyyy",
        da_DK: "dddd !den d. mmmm yyyy",
        de: "dddd, d. mmmm yyyy",
        de_AT: "dddd, d. mmmm yyyy",
        de_CH: "dddd, d. mmmm yyyy",
        de_DE: "dddd, d. mmmm yyyy",
        de_GR: "dddd, d. mmmm yyyy",
        de_LU: "dddd, d. mmmm yyyy",
        default: "yyyy-mm-dd",
        el: "dddd, d mmmm yyyy",
        el_CY: "dddd, d mmmm yyyy",
        el_GR: "dddd, d mmmm yyyy",
        en: "dddd, mmm d, yyyy",
        en_AU: "dddd, d mmm yyyy",
        en_CA: "dddd, mmm d, yyyy",
        en_GB: "dddd, d mmm yyyy",
        en_IE: "dddd d mmm yyyy",
        en_IN: "dddd d mmm yyyy",
        en_MT: "dddd, d mmm yyyy",
        en_NZ: "dddd, d mmm yyyy",
        en_PH: "dddd, d mmm yyyy",
        en_SG: "dddd, d mmm yyyy",
        en_US: "dddd, mmm d, yyyy",
        en_ZA: "dddd, d mmm yyyy",
        es: "dddd, d !de mmm !de yyyy",
        es_AR: "dddd, d !de mmm !de yyyy",
        es_BO: "dddd, d !de mmm !de yyyy",
        es_CL: "dddd, d !de mmm !de yyyy",
        es_CO: "dddd, d !de mmm !de yyyy",
        es_CR: "dddd, d !de mmm !de yyyy",
        es_CU: "dddd, d !de mmm !de yyyy",
        es_DO: "dddd, d !de mmm !de yyyy",
        es_EC: "dddd, d !de mmm !de yyyy",
        es_ES: "dddd, d !de mmm !de yyyy",
        es_GT: "dddd, d !de mmm !de yyyy",
        es_HN: "dddd d !de mmm !de yyyy",
        es_MX: "dddd, d !de mmm !de yyyy",
        es_NI: "dddd, d !de mmm !de yyyy",
        es_PA: "dddd, d !de mmm !de yyyy",
        es_PE: "dddd, d !de mmm !de yyyy",
        es_PR: "dddd, d !de mmm !de yyyy",
        es_PY: "dddd, d !de mmm !de yyyy",
        es_SV: "dddd, d !de mmm !de yyyy",
        es_US: "dddd, d !de mmm !de yyyy",
        es_UY: "dddd, d !de mmm !de yyyy",
        es_VE: "dddd, d !de mmm !de yyyy",
        et: "dddd, d. mmmm yyyy",
        et_EE: "dddd, d. mmmm yyyy",
        fi: "dddd d. mmmm yyyy",
        fi_FI: "dddd d. mmmm yyyy",
        fr: "dddd d mmmm yyyy",
        fr_BE: "dddd d mmmm yyyy",
        fr_CA: "dddd d mmmm yyyy",
        fr_CH: "dddd, d mmmm yyyy",
        fr_FR: "dddd d mmmm yyyy",
        fr_LU: "dddd d mmmm yyyy",
        ga: "dddd d mmmm yyyy",
        ga_IE: "dddd d mmmm yyyy",
        hi: "dddd, d mmmm yyyy",
        hi_IN: "dddd, d mmmm yyyy",
        hr: "dddd, d. mmmm yyyy.",
        hr_HR: "dddd, d. mmmm yyyy.",
        hu: "yyyy. mmmm d., dddd",
        hu_HU: "yyyy. mmmm d., dddd",
        in: "dddd, d mmmm yyyy",
        in_ID: "dddd, d mmmm yyyy",
        is: "dddd, d. mmmm yyyy",
        is_IS: "dddd, d. mmmm yyyy",
        it: "dddd d mmmm yyyy",
        it_CH: "dddd, d mmmm yyyy",
        it_IT: "dddd d mmmm yyyy",
        iw: "dddd, d בmmmm yyyy",
        iw_IL: "dddd, d בmmmm yyyy",
        ja: "yyyy年m月d日dddd",
        ja_JP: "yyyy年m月d日dddd",
        ko: "yyyy년 mmmm d일 dddd",
        ko_KR: "yyyy년 mmmm d일 dddd",
        lt: "yyyy !m. mmmm d !d., dddd",
        lt_LT: "yyyy !m. mmmm d !d., dddd",
        lv: "dddd, yyyy. !gada d. mmmm",
        lv_LV: "dddd, yyyy. !gada d. mmmm",
        mk: "dddd, d mmmm yyyy",
        mk_MK: "dddd, d mmmm yyyy",
        ms: "dddd, d mmmm yyyy",
        ms_MY: "dddd, d mmmm yyyy",
        mt: "dddd, d !ta’ mmmm yyyy",
        mt_MT: "dddd, d !ta’ mmmm yyyy",
        nl: "dddd d mmmm yyyy",
        nl_BE: "dddd d mmmm yyyy",
        nl_NL: "dddd d mmmm yyyy",
        no: "dddd d. mmmm yyyy",
        no_NO: "dddd d. mmmm yyyy",
        pl: "dddd, d mmmm yyyy",
        pl_PL: "dddd, d mmmm yyyy",
        pt: "dddd, d !de mmmm !de yyyy",
        pt_BR: "dddd, d !de mmmm !de yyyy",
        pt_PT: "dddd, d !de mmmm !de yyyy",
        ro: "dddd, d mmmm yyyy",
        ro_RO: "dddd, d mmmm yyyy",
        ru: "dddd, d mmmm yyyy !г.",
        ru_RU: "dddd, d mmmm yyyy !г.",
        sk: "dddd, d. mmmm yyyy",
        sk_SK: "dddd, d. mmmm yyyy",
        sl: "dddd, d. mmmm yyyy",
        sl_SI: "dddd, d. mmmm yyyy",
        sq: "dddd, d mmmm yyyy",
        sq_AL: "dddd, d mmmm yyyy",
        sr: "dddd, d. mmmm yyyy.",
        sr_BA: "dddd, d. mmmm yyyy.",
        sr_CS: "dddd, d. mmmm yyyy.",
        sr_ME: "dddd, d. mmmm yyyy.",
        sr_RS: "dddd, d. mmmm yyyy.",
        sv: "dddd d mmmm yyyy",
        sv_SE: "dddd d mmmm yyyy",
        th: "ddddที่ d mmmm y",
        th_TH: "ddddที่ d mmmm y",
        tr: "d mmmm yyyy dddd",
        tr_TR: "d mmmm yyyy dddd",
        uk: "dddd, d mmmm yyyy !p.",
        uk_UA: "dddd, d mmmm yyyy !p.",
        vi: "dddd, d mmmm, yyyy",
        vi_VN: "dddd, d mmmm, yyyy",
        zh: "yyyy年m月d日dddd",
        zh_CN: "yyyy年m月d日dddd",
        zh_HK: "yyyy年m月d日dddd",
        zh_SG: "yyyy年m月d日dddd",
        zh_TW: "yyyy年m月d日 dddd"
    };
    return DatepickerUtils;
}());
export { DatepickerUtils };
