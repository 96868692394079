var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MIN_CHARS_TO_SEARCH, RESULT, SEARCH_TYPE_PAGE_PROPERTY, TYPEAHEAD_SECTION_PAGE_PROPERTY, TypeaheadBase } from "./typeaheadBase";
import { BehaviorSubject, EMPTY as observableEmpty, fromEvent as observableFromEvent, merge as observableMerge, of as observableOf, } from "rxjs";
import $ from "../jquery";
import { ClickStream } from "../../clickstream";
import { AjaxService } from "../ajax";
import { catchError, distinctUntilChanged, filter, map, share, skipUntil, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { VisitorProfile } from "../../visitorProfile";
import { SubfolderUrlPrefix } from "../../subfolderUrlPrefix";
import { isDefinedAndNotNull } from "../utils/objectUtil";
import { NearbyURL } from "../nearbyURL";
import { RecentSearchesAccessor } from "./recentSearchesAccessor";
var SEARCH_AJAX_URL = "/orion/ajax/typeahead?";
var SEARCH_PAGE_URL = "/searchResults/all?";
var NEARBY_SEARCH = ".nearby-search";
var SEE_ALL_RESULTS_NEARBY = ".see-all-results-nearby";
var SEARCH_ID_PAGE_PROPERTY = "searchId";
var SEARCH_TERM_PAGE_PROPERTY = "searchTerm";
var SEARCH_METHOD_PAGE_PROPERTY = "searchMethod";
var RECENT_SEARCHES_CONTAINER = ".recent-searches";
var RECENT_SEARCHES_TITLE = ".recent-searches-title";
var MAX_RECENT_SEARCHES = 5;
var MAX_RECENT_SEARCH_LENGTH = 100;
var RECENT_SEARCHES_SEPARATOR = "|";
var SELECT_LINK_PAGE_ACTION = "select_typeahead_link";
var LIST_INDEX_PAGE_PROPERTY = "listIndex";
var RESULT_ID_PAGE_PROPERTY = "id";
var CALLING_SERVLET_PAGE_PROPERTY = "callingServlet";
var TYPEAHEAD_PROFILE_TYPE = "TypeaheadSearch";
var ExperienceTypeahead = (function (_super) {
    __extends(ExperienceTypeahead, _super);
    function ExperienceTypeahead($window, parentElement, options) {
        var _this = _super.call(this, $window, parentElement, options) || this;
        _this.searchIsNearby = false;
        _this.SEARCH_AJAX_URL = SEARCH_AJAX_URL;
        _this.hasAnyRecentSearches = false;
        _this.hasTrackedGeoScopedSearch = false;
        _this.nearbySearch = parentElement.find(NEARBY_SEARCH);
        _this.$seeAllResultsNearby = parentElement.find(SEE_ALL_RESULTS_NEARBY);
        var subfolderUrlPrefix = new SubfolderUrlPrefix(_this.$window.document);
        _this.searchPageUrl = subfolderUrlPrefix.apply(SEARCH_PAGE_URL);
        _this.nearbyURL = new NearbyURL(_this.$window, new AjaxService());
        _this.$recentSearchesContainer = parentElement.find(RECENT_SEARCHES_CONTAINER);
        _this.$recentSearchesTitle = _this.$recentSearchesContainer.find(RECENT_SEARCHES_TITLE);
        _this.$recentSearchesAccessor = new RecentSearchesAccessor($window);
        _this.initRecentSearches(_this.$recentSearchesContainer);
        observableFromEvent(_this.nearbySearch, "click")
            .subscribe(function () { return _this.doNearbySearch(); });
        return _this;
    }
    ExperienceTypeahead.prototype.getCurrentSearchDataWithoutDestinationScope = function () {
        var displayText = this.getCurrentSearchTerm();
        if (displayText && displayText.length >= MIN_CHARS_TO_SEARCH) {
            return { displayText: displayText, url: this.searchPageUrl + $.param({ text: displayText }) };
        }
        else {
            return null;
        }
    };
    ExperienceTypeahead.prototype.storeRecentSearchData = function (searchData) {
        this.addRecentSearchData(searchData);
    };
    ExperienceTypeahead.prototype.displayRecentSearches = function (show) {
        if (show && isDefinedAndNotNull(this.nearbySearch)) {
            this.nearbySearch.show();
            if (this.isEagleSearchTest) {
                this.$recentSearchesTitle.toggleClass("d-none", !this.hasAnyRecentSearches);
            }
        }
        this.$recentSearchesContainer.toggleClass("d-none", !show);
    };
    ExperienceTypeahead.prototype.isNearbySearch = function () {
        return this.searchIsNearby;
    };
    ExperienceTypeahead.prototype.watchFreeTextSearchRequest = function () {
        var _this = this;
        this.searchInput$.pipe(filter(function (searchInput) { return searchInput.length < MIN_CHARS_TO_SEARCH; }))
            .subscribe(function () {
            if (isDefinedAndNotNull(_this.searchResults)) {
                _this.searchResults.empty();
            }
            if (isDefinedAndNotNull(_this.nearbySearch)) {
                _this.nearbySearch.show();
            }
        });
        var searchInputInteraction$ = observableMerge(observableFromEvent(this.searchInput, "keydown"), observableFromEvent(this.searchInput, "paste"), observableFromEvent(this.searchInput, "change"), observableFromEvent(this.searchInput, this.getOpenTrigger()));
        var validSearchRequest$ = this.searchRequest$.pipe(skipUntil(searchInputInteraction$), filter(function (_a) {
            var searchInput = _a[0], destId = _a[1];
            if (_this.isEagleSearchTest && _this.searchIsNearby) {
                if (searchInput !== _this.nearbySearch.text().trim()) {
                    _this.$seeAllResultsNearby.addClass("d-none");
                    _this.showFocusedFlyout();
                    return true;
                }
                _this.hideFocusedFlyout();
                _this.$seeAllResultsNearby.removeClass("d-none");
                return false;
            }
            return searchInput.length >= MIN_CHARS_TO_SEARCH;
        }), share());
        validSearchRequest$.pipe(switchMap(function (_a) {
            var searchTerm = _a[0], destId = _a[1];
            var params = { text: searchTerm, destId: destId, callingServlet: _this.servletName };
            return _this.ajaxService.doGet(_this.SEARCH_AJAX_URL + $.param(params)).pipe(catchError(function () { return observableEmpty; }), map(function (result) { return [searchTerm, result.response]; }));
        }))
            .subscribe(function (_a) {
            var searchTerm = _a[0], html = _a[1];
            _this.updateResults(searchTerm, html);
            if ('' !== _this.searchInput$.value) {
                _this.nearbySearch.hide();
                if (_this.reopenFlyout) {
                    _this.showFocusedFlyout();
                }
            }
            else {
                _this.searchResults.empty();
            }
            ClickStream.postClickStreamWithTrackingArguments({
                servletName: _this.servletName,
                pageAction: "visible",
                pageProperties: {
                    elementId: 'header_search_experience_typeahead_option',
                    numberOfOptions: $(html).find(".typeahead-result").length,
                    searchTerm: searchTerm
                }
            });
        });
        if (this.geoScopeButtons.length) {
            validSearchRequest$.subscribe(function (_a) {
                var inputText = _a[0], destId = _a[1];
                if (!_this.hasTrackedGeoScopedSearch) {
                    _this.hasTrackedGeoScopedSearch = true;
                    ClickStream.postClickStream(_this.servletName, "click_search_geo_scope", null, destId, null, inputText);
                }
            });
        }
    };
    ExperienceTypeahead.prototype.notifyObservers = function (text, destId, searchButtonClicked, url) {
        var _this = this;
        if (!text.length) {
            return;
        }
        var subject = searchButtonClicked ? this.searchButtonObservable : this.textBoxObservable;
        var triggerEvent = function () {
            return subject.next(_this.constructSearchData(text, url, destId));
        };
        var pageProperties = TYPEAHEAD_SECTION_PAGE_PROPERTY;
        var searchId = this.isEagleSearchTest ?
            this.searchResults.find(".eagle-header-search").data("search-id")
            : this.searchResults.find(".category-results").data("search-id");
        this.addRecentSearchTerm(text, searchId);
        pageProperties[SEARCH_ID_PAGE_PROPERTY] = searchId;
        pageProperties[SEARCH_TERM_PAGE_PROPERTY] = text;
        pageProperties[SEARCH_METHOD_PAGE_PROPERTY] = 'MenuSearch';
        pageProperties[SEARCH_TYPE_PAGE_PROPERTY] = "FREETEXT";
        var pageAction = this.isEagleSearchTest ? "search" : "full_search_results";
        ClickStream.postClickStreamWithCallback({
            pageAction: pageAction,
            pageProperties: pageProperties,
            productAttribute: text,
            servletName: this.servletName
        }, triggerEvent.bind(this));
    };
    ExperienceTypeahead.prototype.constructSearchData = function (text, url, destId) {
        return {
            displayText: text,
            url: url || this.searchPageUrl + $.param({ text: text, destId: destId })
        };
    };
    ExperienceTypeahead.prototype.createTextSearchData = function (text) {
        return this.constructSearchData(text, null, this.getDestId(this.geoScopeButtons));
    };
    ExperienceTypeahead.prototype.resultSelected = function ($elem, searchTerm) {
        var _this = this;
        {
            this.searchIsNearby = false;
            var searchData_1 = this.getSearchData($elem);
            var pageProperties = TYPEAHEAD_SECTION_PAGE_PROPERTY;
            pageProperties[RESULT_ID_PAGE_PROPERTY] = $elem.data("id");
            pageProperties[SEARCH_TYPE_PAGE_PROPERTY] = $elem.data("type");
            pageProperties[LIST_INDEX_PAGE_PROPERTY] = $elem.prevAll(RESULT).length;
            pageProperties[CALLING_SERVLET_PAGE_PROPERTY] = this.servletName;
            var $resultsElem = this.searchResults.find(".category-results");
            pageProperties[SEARCH_ID_PAGE_PROPERTY] = this.isEagleSearchTest ? searchData_1.searchId : $resultsElem.data("search-id");
            pageProperties[SEARCH_TERM_PAGE_PROPERTY] = this.isEagleSearchTest
                ? this.searchResults.find(".eagle-header-search").data("search-term")
                : $resultsElem.data("search-term");
            if (!this.options.preventStoringRecentSearches) {
                this.addRecentSearchData(searchData_1);
            }
            var visitorData = {
                typeaheadSelection: searchData_1.displayText,
                visitorProfileType: TYPEAHEAD_PROFILE_TYPE
            };
            new VisitorProfile(this.ajaxService).postVisitorProfile(visitorData);
            var destinationID = $elem.attr("data-destinationid") || "";
            ClickStream.postClickStreamWithCallback({
                destinationID: destinationID,
                pageAction: SELECT_LINK_PAGE_ACTION,
                pageProperties: pageProperties,
                productAttribute: searchData_1.url,
                servletName: this.servletName
            }, (function () {
                _this.textBoxObservable.next(searchData_1);
            }).bind(this));
        }
    };
    ExperienceTypeahead.prototype.getSearchData = function ($elem) {
        var userSearchSelection = "";
        $elem.find('[data-identifier="search-result-item"]').each(function () {
            userSearchSelection += $(this).text();
        });
        var linkUrl = $elem.data("href");
        if (!this.isEagleSearchTest) {
            return { displayText: userSearchSelection, url: linkUrl };
        }
        var type = $elem.data("type") || null;
        var imageUrl = $elem.find('.typeahead-product-img img').attr('src') || null;
        var destinationName = $elem.find('.subtitle span').eq(0).text() || null;
        var searchId = $elem.parents(".eagle-header-search").data("search-id");
        return {
            displayText: userSearchSelection,
            url: linkUrl,
            type: type,
            imageUrl: imageUrl,
            destinationName: destinationName,
            searchId: searchId
        };
    };
    ExperienceTypeahead.prototype.addRecentSearchTerm = function (searchTerm, searchId) {
        if (searchTerm.length < MIN_CHARS_TO_SEARCH) {
            return;
        }
        if (searchTerm === this.nearbySearch.text().trim()) {
            return;
        }
        searchTerm = searchTerm.substr(0, MAX_RECENT_SEARCH_LENGTH);
        this.addRecentSearchData({ displayText: searchTerm, searchId: searchId || undefined });
    };
    ExperienceTypeahead.prototype.doNearbySearch = function () {
        var _this = this;
        var pageProperties = TYPEAHEAD_SECTION_PAGE_PROPERTY;
        pageProperties[SEARCH_TYPE_PAGE_PROPERTY] = "NEARBY";
        ClickStream.postClickStreamWithCallback({
            pageAction: SELECT_LINK_PAGE_ACTION,
            pageProperties: pageProperties,
            servletName: this.servletName
        }, function () {
            var triggerEvent = function (url) {
                _this.searchIsNearby = true;
                var displayText = _this.nearbySearch.text().trim();
                _this.notifyObservers(displayText, undefined, false, url);
            };
            _this.nearbyURL.getNearbyUrl().subscribe(triggerEvent.bind(_this));
        });
    };
    ExperienceTypeahead.prototype.initRecentSearches = function ($recentSearchesContainer) {
        var _this = this;
        if (!$recentSearchesContainer.length) {
            this.$recentSearchesAccessor.removeRecentSearches();
            return;
        }
        var RECENT_SEARCH_CLEAR_HISTORY = ".clear-history";
        var RECENT_SEARCH_TERMS_CLASS = ".recent-search-terms";
        var RECENT_SEARCH_TERM_CLASS = "recent-search-term";
        this.recentSearchTerms$ = this.createRecentSearchTermsSubject();
        var $clearHistoryButton = $recentSearchesContainer.find(RECENT_SEARCH_CLEAR_HISTORY);
        var $termsContainer = $recentSearchesContainer.find(RECENT_SEARCH_TERMS_CLASS);
        var typeToIcon = {
            ATTRACTION: "location-pin-transparent.svg",
            DESTINATION: "location-pin-transparent.svg",
            RECOMMENDATION: "lightbulb-transparent.svg",
            SEARCH: "magnifying-glass-transparent.svg",
            DESTINATION_TAG: "ticket-transparent.svg",
        };
        var renderRecentSearchIcon = function (type) {
            var basePath = "/orion/images/icon/typeahead";
            var iconSrc = type ? typeToIcon[type] : typeToIcon["SEARCH"];
            return $("<img>", {
                "src": basePath + "/" + iconSrc,
                "width": "32",
                "height": "32",
                "alt": ""
            });
        };
        var renderEagleRecentSearchItem = function (searchData) {
            var label = $("<span>", {
                "class": "search-result-title",
                "data-identifier": "search-result-item"
            }).text(searchData.displayText);
            var destinationName = searchData.destinationName
                ? $("<div>", { "class": "subtitle" }).text(searchData.destinationName)
                : $();
            var image = searchData.imageUrl && searchData.type === "PRODUCT"
                ? $("<img>", { "src": searchData.imageUrl, "alt": "" })
                : renderRecentSearchIcon(searchData.type);
            var labelContainer = $("<div>", {
                "class": "typeahead-result-label"
            }).append(label, destinationName);
            var imageContainer = $("<div>", {
                "class": (searchData.imageUrl ? "typeahead-result-img loading" : "typeahead-result-icon")
            }).append(image);
            var container = $("<div>", { "class": "typeahead-result-container" })
                .append(imageContainer, labelContainer);
            return $("<div>", {
                "class": "typeahead-result " + RECENT_SEARCH_TERM_CLASS,
                "data-href": searchData.url ? searchData.url : "",
                "data-search-id": searchData.searchId ? searchData.searchId : ""
            }).append(container);
        };
        var renderRecentSearchItem = function (searchData) {
            return $("<div>", {
                "class": RECENT_SEARCH_TERM_CLASS + " pb-1",
                "data-href": searchData.url ? searchData.url : "",
                "data-search-id": searchData.searchId ? searchData.searchId : ""
            }).text(searchData.displayText);
        };
        this.searchInput$.pipe(map(function (searchTerm) { return searchTerm.length >= MIN_CHARS_TO_SEARCH; }), distinctUntilChanged(), switchMap(function (suppressRecentSearch) {
            return suppressRecentSearch ? observableOf([]) : _this.recentSearchTerms$;
        }))
            .subscribe(function (terms) {
            _this.hasAnyRecentSearches = terms.length > 0;
            var emptySearchTerm = _this.searchInput$.value === '';
            var displayRecentSearches = (_this.isEagleSearchTest) ? emptySearchTerm : _this.hasAnyRecentSearches;
            if (displayRecentSearches) {
                var $terms = terms
                    .map(_this.ensureSearchData)
                    .map(function (searchData) {
                    return _this.isEagleSearchTest ? renderEagleRecentSearchItem(searchData) : renderRecentSearchItem(searchData);
                });
                $termsContainer.empty().append($terms);
            }
            _this.displayRecentSearches(displayRecentSearches);
        });
        observableFromEvent($clearHistoryButton, "click")
            .subscribe(function () {
            _this.clearRecentSearches();
        });
        var recentSearchClick$ = observableFromEvent($termsContainer, "click").pipe(map(function (event) {
            _this.searchIsNearby = false;
            if (_this.isEagleSearchTest
                && $(event.target).closest(".recent-search-term").length === 1) {
                return $(event.target).closest(".recent-search-term");
            }
            return $(event.target);
        }), filter(function (target) { return target.hasClass(RECENT_SEARCH_TERM_CLASS); }));
        recentSearchClick$.pipe(filter(function (target) { return target.data("href"); }))
            .subscribe(function (target) {
            var url = target.data("href");
            var targetText = _this.isEagleSearchTest ?
                target.find('.search-result-title').text()
                : target.text().trim();
            var searchId = target.data("searchId");
            var obsValue = (_this.isEagleSearchTest)
                ? _this.getRecentSearchByUrl(url)
                : { displayText: targetText, url: url };
            ClickStream.postClickStreamWithCallback({
                pageAction: _this.isEagleSearchTest ? "select_typeahead_link" : "click_search_previous",
                productAttribute: targetText,
                servletName: _this.servletName,
                pageProperties: {
                    searchType: "RECENT",
                    searchTerm: targetText,
                    searchId: searchId
                }
            }, (function () { return _this.textBoxObservable.next(obsValue); }).bind(_this));
        });
        recentSearchClick$.pipe(filter(function (target) { return !target.data("href"); }), map(function (target) {
            var term = _this.isEagleSearchTest ? target.find("[data-identifier='search-result-item']").text() : target.text();
            return {
                term: term,
                searchId: target.data("searchId")
            };
        }), tap(function (_a) {
            var term = _a.term, searchId = _a.searchId;
            return ClickStream.postClickStreamWithTrackingArguments({
                servletName: _this.servletName,
                pageAction: _this.isEagleSearchTest ? "select_typeahead_link" : "click_search_previous",
                pageProperties: {
                    searchType: "RECENT",
                    searchTerm: term,
                    searchId: searchId
                },
                productAttribute: term
            });
        }), withLatestFrom(this.searchDestId$))
            .subscribe(function (_a) {
            var recentSearch = _a[0], destId = _a[1];
            _this.textBoxObservable.next({
                displayText: recentSearch.term,
                url: _this.searchPageUrl + $.param({ text: recentSearch.term, destId: destId })
            });
        });
    };
    ExperienceTypeahead.prototype.createRecentSearchTermsSubject = function () {
        var recentSearchTerms = this.$recentSearchesAccessor.fetchRecentSearches();
        return new BehaviorSubject(recentSearchTerms);
    };
    ExperienceTypeahead.prototype.ensureSearchData = function (data) {
        return typeof (data) === "string" ? { displayText: data } : data;
    };
    ExperienceTypeahead.prototype.clearRecentSearches = function () {
        var _this = this;
        if (!this.recentSearchTerms$) {
            return;
        }
        var currentRecentSearches = this.recentSearchTerms$.value;
        var productAttr = currentRecentSearches
            .map(function (recentSearch) { return _this.ensureSearchData(recentSearch).displayText; })
            .join(RECENT_SEARCHES_SEPARATOR);
        ClickStream.postClickStreamWithTrackingArguments({
            servletName: this.servletName,
            pageAction: this.isEagleSearchTest ? "select_typeahead_link" : "click_search_previous",
            pageProperties: {
                searchType: "RECENT"
            },
            productAttribute: productAttr
        });
        this.$recentSearchesAccessor.removeRecentSearches();
        this.recentSearchTerms$.next([]);
    };
    ExperienceTypeahead.prototype.addRecentSearchData = function (searchData) {
        var _this = this;
        if (!this.recentSearchTerms$ || this.searchIsNearby) {
            return;
        }
        var newRecentSearches = this.recentSearchTerms$.value
            .map(this.ensureSearchData)
            .filter(function (recentData) { return !_this.areRecentSearchDataEqual(recentData, searchData); })
            .slice(0, MAX_RECENT_SEARCHES - 1);
        newRecentSearches.unshift(searchData);
        this.$recentSearchesAccessor.setRecentSearches(newRecentSearches);
        this.recentSearchTerms$.next(newRecentSearches);
    };
    ExperienceTypeahead.prototype.areRecentSearchDataEqual = function (recentData, searchData) {
        if (this.isEagleSearchTest) {
            return recentData.displayText + recentData.destinationName === searchData.displayText + searchData.destinationName;
        }
        return recentData.displayText === searchData.displayText;
    };
    ExperienceTypeahead.prototype.getRecentSearchByUrl = function (recentSearchUrl) {
        return this.recentSearchTerms$
            .value
            .find(function (recentData) { return recentData.url === recentSearchUrl; });
    };
    return ExperienceTypeahead;
}(TypeaheadBase));
export { ExperienceTypeahead };
