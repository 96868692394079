import "../../scss/dateRangePicker.scss";
import "../common/pickadate";
import { fromEvent as observableFromEvent, Subject } from "rxjs";
import { map, throttleTime } from "rxjs/operators";
import { DatepickerUtils } from "../datepickerUtils";
import { ClickStream } from "../clickstream";
import { KeyboardUtil } from "./KeyboardUtil";
import { isDesktopLayout } from "../../ts/common/utils/responsive";
import $ from "../../ts/common/jquery";
var DateSelectionPreviewOnHover;
(function (DateSelectionPreviewOnHover) {
    DateSelectionPreviewOnHover[DateSelectionPreviewOnHover["ShowStartDateSelectionPreview"] = 0] = "ShowStartDateSelectionPreview";
    DateSelectionPreviewOnHover[DateSelectionPreviewOnHover["ShowEndDateSelectionPreview"] = 1] = "ShowEndDateSelectionPreview";
    DateSelectionPreviewOnHover[DateSelectionPreviewOnHover["None"] = 2] = "None";
})(DateSelectionPreviewOnHover || (DateSelectionPreviewOnHover = {}));
var DateRangePicker = (function () {
    function DateRangePicker(parentElement, container, clearDateButton, options) {
        this.DATE_SELECTED_CLASS = "date-selected";
        this.HEADER_SELECTOR = ".date-picker-header";
        this.BUTTONS_SELECTOR = ".calendar-buttons";
        this.CLEAR_BUTTON_SELECTOR = ".clear-dates";
        this.APPLY_BUTTON_SELECTOR = ".apply-dates";
        this.MODAL_BACKGROUND_SELECTOR = ".picker-background";
        this.openObs = new Subject();
        this.applyObs = new Subject();
        this.closeObs = new Subject();
        this.resetObs = new Subject();
        this.applyOnClose = true;
        this.initialized = false;
        this.updateStartDate = true;
        this.$parentElement = parentElement;
        this.container = container;
        this.clearButton = clearDateButton;
        this.options = options;
        this.setUpModalBackground();
        if (options.screenSizeToShowModal) {
            this.$parentElement.addClass(options.screenSizeToShowModal + "-in-modal");
            observableFromEvent(this.$parentElement.find(".datepicker"), "mousedown")
                .subscribe(function (e) {
                e.preventDefault();
            });
        }
        this.initialize();
    }
    DateRangePicker.getLocaleInJSFormat = function (serverLocaleRepresentation) {
        return serverLocaleRepresentation.replace("_", "-");
    };
    DateRangePicker.getTodayTimeStamp = function () {
        var currentDateAndTime = new Date();
        return new Date(currentDateAndTime.getFullYear(), currentDateAndTime.getMonth(), currentDateAndTime.getDate()).getTime();
    };
    DateRangePicker.getDateSelectionPreviewOnHoverState = function (hoveredDate, startDate, endDate, updateStartDate) {
        if (DateRangePicker.shouldShowStartDateRangePreview(hoveredDate, startDate, endDate, updateStartDate)) {
            return DateSelectionPreviewOnHover.ShowStartDateSelectionPreview;
        }
        else if (DateRangePicker.shouldShowEndDateRangePreview(hoveredDate, startDate, endDate, updateStartDate)) {
            return DateSelectionPreviewOnHover.ShowEndDateSelectionPreview;
        }
        else {
            return DateSelectionPreviewOnHover.None;
        }
    };
    DateRangePicker.shouldShowStartDateRangePreview = function (hoveredDate, startDate, endDate, updateStartDate) {
        return updateStartDate
            && startDate != null
            && endDate != null
            && hoveredDate < startDate;
    };
    DateRangePicker.shouldShowEndDateRangePreview = function (hoveredDate, startDate, endDate, updateStartDate) {
        return !updateStartDate
            && startDate != null
            && endDate != null
            && hoveredDate > endDate;
    };
    DateRangePicker.prototype.open = function () {
        this.picker.open();
    };
    DateRangePicker.prototype.close = function () {
        this.picker.close();
    };
    DateRangePicker.prototype.clear = function () {
        if (!this.initialized) {
            return;
        }
        this.picker.clear();
        this.startDate = null;
        this.endDate = null;
        this.clearRangePicker();
        this.changeDisplayWhenValueChanged();
        this.updateInputField("");
    };
    DateRangePicker.prototype.update = function (dateRange, updateFromExtraSetter) {
        if (updateFromExtraSetter === void 0) { updateFromExtraSetter = false; }
        if (this.initialized) {
            this.startDate = dateRange.startDate;
            this.endDate = dateRange.endDate;
            if (updateFromExtraSetter) {
                this.pseudoClearDatePicker();
            }
            else if (dateRange.startDate && dateRange.endDate) {
                this.updateDatePicker(this.startDate, this.endDate, this.locale);
                this.changeDisplayWhenValueChanged();
                this.updateCurrentViewMonth(dateRange.startDate);
            }
        }
    };
    DateRangePicker.prototype.isInitialized = function () {
        return this.initialized;
    };
    DateRangePicker.prototype.getSelectedDate = function () {
        return !this.initialized ? null : {
            endDate: this.endDate ? new Date(this.endDate) : null,
            startDate: this.startDate ? new Date(this.startDate) : null
        };
    };
    DateRangePicker.prototype.getOpenObservable = function () {
        return this.openObs;
    };
    DateRangePicker.prototype.getApplyObservable = function () {
        return this.applyObs;
    };
    DateRangePicker.prototype.getCloseObservable = function () {
        return this.closeObs;
    };
    DateRangePicker.prototype.getResetObservable = function () {
        return this.resetObs;
    };
    DateRangePicker.prototype.applyDatesFilter = function () {
        this.applyObs.next({ startDate: this.startDate, endDate: this.endDate });
        this.changeDisplayWhenValueChanged();
    };
    DateRangePicker.prototype.getDateRangeAsString = function () {
        return this.getDateRangeString(this.startDate, this.endDate, this.locale);
    };
    DateRangePicker.prototype.setUpModalBackground = function () {
        this.$parentElement.find(this.MODAL_BACKGROUND_SELECTOR).click($.noop);
    };
    DateRangePicker.prototype.initialize = function () {
        var _this = this;
        var pickerInput = $('input[name="pickerDate"]', this.$parentElement);
        if (!pickerInput.length) {
            return;
        }
        this.pickerInput = pickerInput;
        var shortWeekdays = pickerInput.data("weekdaysShort").split(",");
        var longWeekdays = pickerInput.data("weekdaysFull").split(",");
        var shortMonths = pickerInput.data("monthsShort").split(",");
        var longMonths = pickerInput.data("monthsFull").split(",");
        var maxAvailableDate = DatepickerUtils.parseYMDstring(pickerInput.data("maxSelectableDate"));
        var min = new Date();
        pickerInput.pickadate({
            clear: "",
            close: "",
            closeOnSelect: false,
            firstDay: pickerInput.data("startWeekOnMonday"),
            format: DatepickerUtils.getDateFormatForLocale(pickerInput.data("localeForDatePattern")),
            klass: {
                navNext: "monthScroll next icon-li",
                navPrev: "monthScroll prev icon-li"
            },
            max: maxAvailableDate,
            min: min,
            monthsFull: longMonths,
            monthsShort: shortMonths,
            onClose: function () {
                _this.applyOnClose = _this.hasDates();
                _this.handleOnClose();
            },
            onOpen: function (event) {
                _this.handleOnOpen(event);
            },
            onRender: function () {
                _this.addCustomCalendarElements();
            },
            onSet: function (event) {
                _this.handleCalendarUpdate(event);
            },
            today: "",
            weekdaysFull: longWeekdays,
            weekdaysShort: shortWeekdays,
        });
        this.picker = pickerInput.pickadate("picker");
        this.changeDisplayWhenValueChanged();
        if (this.clearButton) {
            observableFromEvent(this.clearButton, "click").subscribe(function (e) {
                _this.clear();
                _this.applyObs.next({ startDate: null, endDate: null });
            });
        }
        this.picker.$holder.on("keydown", function (event) {
            if (KeyboardUtil.isEnterKey(event)) {
                event.stopPropagation();
                event.preventDefault();
                _this.picker.close();
                if (!_this.hasDates()) {
                    _this.applyObs.next({ startDate: _this.startDate, endDate: _this.endDate });
                }
            }
        });
        var $modalBackground = this.$parentElement.find(this.MODAL_BACKGROUND_SELECTOR);
        observableFromEvent($modalBackground, "click").subscribe(function (event) {
            if (_this.hasDates()) {
                return;
            }
            event.stopPropagation();
            event.preventDefault();
            _this.applyOnClose = false;
            _this.handleOnClose();
            _this.picker.close();
        });
        this.updateStartDate = true;
        this.locale = DateRangePicker.getLocaleInJSFormat(pickerInput.data("localeForDatePattern"));
        var placeholder = pickerInput.attr("placeholder");
        var value = pickerInput.attr("value");
        $("#dateRangeValue").text(value ? value : placeholder);
        this.initialized = true;
    };
    DateRangePicker.prototype.hasDates = function () {
        var selectedDates = this.getSelectedDate();
        return selectedDates !== null &&
            selectedDates.startDate !== null &&
            selectedDates.endDate !== null;
    };
    DateRangePicker.prototype.highlightOnHover = function (hoveredDate) {
        var hoverState = DateRangePicker.getDateSelectionPreviewOnHoverState(hoveredDate, this.startDate, this.endDate, this.updateStartDate);
        switch (hoverState) {
            case DateSelectionPreviewOnHover.ShowStartDateSelectionPreview:
                this.updateStyleForDates(hoveredDate, this.endDate);
                break;
            case DateSelectionPreviewOnHover.ShowEndDateSelectionPreview:
                this.updateStyleForDates(this.startDate, hoveredDate);
                break;
            case DateSelectionPreviewOnHover.None:
                return;
        }
    };
    DateRangePicker.prototype.changeDisplayWhenValueChanged = function () {
        var _this = this;
        var datesSelected = !!this.startDate && !!this.endDate;
        this.$parentElement.closest(".date-range-container")
            .toggleClass("filter-selected", datesSelected);
        this.$parentElement.toggleClass(this.DATE_SELECTED_CLASS, datesSelected);
        if (this.options.isDynamicFilterDatePicker) {
            $("#dynamicFilterDatePickerButton").toggleClass(this.DATE_SELECTED_CLASS, datesSelected);
            $("#pillFiltersDatePickerButton").toggleClass(this.DATE_SELECTED_CLASS, datesSelected);
            $(".trigger-date-modal")
                .get()
                .forEach(function (btn) {
                $(btn).toggleClass(_this.DATE_SELECTED_CLASS, datesSelected);
            });
        }
    };
    DateRangePicker.prototype.subscribeToHoverEventForDateCells = function () {
        var _this = this;
        var isTouchEnabled = !!("ontouchstart" in document.documentElement)
            || navigator.msMaxTouchPoints > 0
            || navigator.maxTouchPoints > 0;
        if (!isTouchEnabled) {
            var today_1 = DateRangePicker.getTodayTimeStamp();
            var selectableDateCells = this.$parentElement.find("td").filter(function () {
                return $(this).children(".picker__day--infocus").data("pick") >= today_1;
            });
            this.$parentElement.find(".picker__box").addClass("hover_active");
            observableFromEvent(selectableDateCells, "mouseenter").pipe(map(function (evt) { return $(evt.target).closest(".picker_day_selectable_dates"); }), throttleTime(10)).subscribe(function ($result) { return _this.mouseInEvent($result); });
            observableFromEvent(selectableDateCells, "mouseleave").pipe(map(function (evt) { return $(evt.target).closest(".picker_day_selectable_dates"); }), throttleTime(10)).subscribe(function () { return _this.mouseOutEvent(); });
        }
        else {
            this.$parentElement.find(".picker__box").removeClass("hover_active");
        }
    };
    DateRangePicker.prototype.mouseInEvent = function (target) {
        var timestamp = target.children(".picker__day--infocus").data("pick");
        var hoveredDate = new Date(timestamp);
        this.highlightOnHover(hoveredDate);
    };
    DateRangePicker.prototype.mouseOutEvent = function () {
        this.updateDatePicker(this.startDate, this.endDate, this.locale);
    };
    DateRangePicker.prototype.updateStyleForDates = function (startDate, endDate) {
        this.removeDateRangePickerStylesClass();
        var $applyDatesButton = this.$parentElement.find(this.APPLY_BUTTON_SELECTOR);
        if (!startDate || !endDate) {
            if (!this.options.allowApplyWithoutDates) {
                $applyDatesButton.prop("disabled", true);
                this.$parentElement.removeClass(this.DATE_SELECTED_CLASS);
            }
            return;
        }
        else if (DatepickerUtils.datesEqual(startDate, endDate)) {
            this.updateStyleSingleDateSelection(startDate);
        }
        else {
            this.updateStyleDateRangeSelection(startDate, endDate);
        }
        $applyDatesButton.prop("disabled", false);
    };
    DateRangePicker.prototype.updateStyleSingleDateSelection = function (startDate) {
        var startTimeStamp = startDate.getTime();
        this.$parentElement.find("td").filter(function () {
            return $(this).children(".picker__day--infocus").data("pick") === startTimeStamp;
        }).addClass("picker_day_selected_single_date");
    };
    DateRangePicker.prototype.updateStyleDateRangeSelection = function (startDate, endDate) {
        var startDateRangeStyleClassName = "picker_day_selected_range_start_date";
        var endDateRangeStyleClassName = "picker_day_selected_range_end_date";
        if (!startDate || !endDate) {
            return;
        }
        var startTimeStamp = startDate.getTime();
        var endTimeStamp = endDate.getTime();
        var selectedDateCells = this.$parentElement.find("td").filter(function () {
            var dateCell = $(this).children(".picker__day--infocus").data("pick");
            return dateCell >= startTimeStamp && dateCell <= endTimeStamp;
        });
        selectedDateCells.addClass("picker_day_selected_range");
        selectedDateCells.find("[data-pick=" + startTimeStamp + "]")
            .parent()
            .addClass(startDateRangeStyleClassName);
        selectedDateCells.find("[data-pick=" + endTimeStamp + "]").parent().addClass(endDateRangeStyleClassName);
    };
    DateRangePicker.prototype.removeDateRangePickerStylesClass = function () {
        this.$parentElement.find("td")
            .removeClass("picker_day_selected_range picker_day_selected_range_end_date")
            .removeClass("picker_day_selected_range_start_date picker_day_selected_single_date");
    };
    DateRangePicker.prototype.addCustomCalendarElements = function () {
        var _this = this;
        var additionalCalendarComponents = $("#additionalCalendarComponents");
        var $pickerHolder = this.$parentElement.find(".picker__holder");
        var $pickerWrap = this.$parentElement.find(".picker__wrap");
        var $pickerBox = this.$parentElement.find(".picker__box");
        if (this.options.screenSizeToShowModal && $(this.HEADER_SELECTOR, $pickerHolder).length === 0) {
            var $datesHeader = $(this.HEADER_SELECTOR, additionalCalendarComponents).clone();
            $pickerWrap.prepend($datesHeader);
            var $close = $(".close", $pickerHolder);
            observableFromEvent($close, "click").subscribe(function () {
                _this.applyOnClose = false;
                _this.picker.close();
            });
        }
        if (!$(this.BUTTONS_SELECTOR, $pickerHolder).length) {
            var $buttonWrapper = $(this.BUTTONS_SELECTOR, additionalCalendarComponents).clone();
            $pickerBox.append($buttonWrapper);
            var $clearDatesButton = $(this.CLEAR_BUTTON_SELECTOR, $pickerHolder);
            var $applyDatesButton = $(this.APPLY_BUTTON_SELECTOR, $pickerHolder);
            observableFromEvent($clearDatesButton, "click").subscribe(function (event) {
                _this.trackPageAction("date_range_cleared", null);
                _this.clearRangePicker();
                _this.applyObs.next({ startDate: _this.startDate, endDate: _this.endDate });
                _this.resetObs.next();
                _this.changeDisplayWhenValueChanged();
            });
            observableFromEvent($applyDatesButton, "click").subscribe(function () {
                var startDate = DatepickerUtils.formatDateToYMDString(_this.startDate);
                var endDate = DatepickerUtils.formatDateToYMDString(_this.endDate);
                if (!_this.hasDates()) {
                    _this.applyObs.next({ startDate: _this.startDate, endDate: _this.endDate });
                }
                _this.picker.close();
                _this.trackPageAction("date_range_filter_apply", { startDate: startDate, endDate: endDate });
            });
        }
        this.trackClickEvent(".next", "date_range_filter_next");
        this.trackClickEvent(".prev", "date_range_filter_prev");
    };
    DateRangePicker.prototype.getSelectedDateString = function () {
        var date = this.picker.get("select");
        if (date) {
            var year = String(date.year);
            var month = String(date.month + 1);
            var dayOfMonth = String(date.date);
            return year + "-" + month + "-" + dayOfMonth;
        }
        return null;
    };
    DateRangePicker.prototype.handleCalendarUpdate = function (event) {
        if (event.select) {
            var selectedDate = this.getSelectedDateString();
            var dateRange = this.getUpdatedDateRange(this.startDate, this.endDate, DatepickerUtils.parseYMDstring(selectedDate));
            this.updateDatePicker(dateRange[0], dateRange[1], this.locale);
        }
        this.updateStyleForCalendar();
        this.subscribeToHoverEventForDateCells();
        if (!event.view) {
            this.updateDatePicker(this.startDate, this.endDate, this.locale);
        }
    };
    DateRangePicker.prototype.handleOnClose = function () {
        if (this.applyOnClose) {
            this.applyDatesFilter();
        }
        this.updateDatePicker(this.startDate, this.endDate, this.locale);
        this.$parentElement.removeClass(DatepickerUtils.DATEPICKER_OPEN_CLASS);
        DatepickerUtils.toggleDatepickerOpenStatus(false);
        this.pickerInput.blur();
        this.applyOnClose = true;
        this.closeObs.next();
    };
    DateRangePicker.prototype.handleOnOpen = function (event) {
        var _this = this;
        setTimeout(function () {
            if (_this.picker.get("open")) {
                _this.$parentElement.addClass(DatepickerUtils.DATEPICKER_OPEN_CLASS);
                if (isDesktopLayout()) {
                    var $dynamicFiltersDisplay = $(".dynamic-filters-display");
                    var $scrollToTarget = $dynamicFiltersDisplay.length ? $dynamicFiltersDisplay : $(".date-filter-section");
                    if ($scrollToTarget) {
                        $('html,body').animate({
                            scrollTop: $scrollToTarget.offset().top
                        }, 1000);
                    }
                }
            }
        }, 500);
        this.trackPageAction("date_range_filter_opened", {});
        this.updateStyleForCalendar();
        this.updateDatePicker(this.startDate, this.endDate, this.locale);
        this.updateStartDate = true;
        this.subscribeToHoverEventForDateCells();
        DatepickerUtils.toggleDatepickerOpenStatus(true);
        this.openObs.next();
    };
    DateRangePicker.prototype.trackClickEvent = function (className, eventName) {
        var _this = this;
        observableFromEvent($(className), "click").subscribe(function () {
            _this.trackPageAction(eventName, {});
        });
    };
    DateRangePicker.prototype.updateDatePicker = function (startDate, endDate, locale) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.updateInputFieldWithDateRange(startDate, endDate, locale);
        this.updateStyleForDates(startDate, endDate);
    };
    DateRangePicker.prototype.updateCurrentViewMonth = function (startDate) {
        var currentView = this.picker.get("view");
        if (currentView && startDate) {
            var shouldUpdate = currentView.year !== startDate.getFullYear() ||
                currentView.month !== startDate.getMonth();
            if (shouldUpdate) {
                this.picker.set("view", startDate);
            }
        }
    };
    DateRangePicker.prototype.pseudoClearDatePicker = function () {
        this.updateInputField("");
        this.updateStyleForDates(null, null);
    };
    DateRangePicker.prototype.updateStyleForCalendar = function () {
        var currentDateAndTime = new Date();
        var today = new Date(currentDateAndTime.getFullYear(), currentDateAndTime.getMonth(), currentDateAndTime.getDate());
        var startTimeStamp = today.getTime();
        var selectedDateCells = this.$parentElement.find("td").filter(function () {
            return $(this).children(".picker__day--infocus").data("pick") >= startTimeStamp;
        });
        selectedDateCells.addClass("picker_day_selectable_dates");
    };
    DateRangePicker.prototype.getDateRangeString = function (startDate, endDate, locale) {
        if (!startDate || !endDate || !locale) {
            return '';
        }
        var monthAndDayComponentOptions = { month: "short", day: "numeric" };
        var dayComponentOption = { day: "numeric" };
        var startDateString = startDate.toLocaleDateString(locale, monthAndDayComponentOptions);
        var endDateString = endDate.toLocaleDateString(locale, (startDate.getMonth() === endDate.getMonth()) ? dayComponentOption : monthAndDayComponentOptions);
        return DatepickerUtils.datesEqual(startDate, endDate)
            ? startDateString
            : startDateString + " - " + endDateString;
    };
    DateRangePicker.prototype.updateInputFieldWithDateRange = function (startDate, endDate, locale) {
        if (!startDate || !endDate) {
            this.updateInputField("");
            return;
        }
        var inputFieldString = this.getDateRangeString(startDate, endDate, locale);
        this.updateInputField(inputFieldString);
    };
    DateRangePicker.prototype.getUpdatedDateRange = function (startDate, endDate, userSelectedDate) {
        if (this.updateStartDate) {
            return this.getDateRangeByUpdatingStartDate(startDate, endDate, userSelectedDate);
        }
        return this.getDateRangeByUpdatingEndDate(startDate, userSelectedDate);
    };
    DateRangePicker.prototype.getDateRangeByUpdatingStartDate = function (startDate, endDate, userSelectedStartDate) {
        this.updateStartDate = false;
        var date = DatepickerUtils.formatDateToYMDString(userSelectedStartDate);
        this.trackPageAction("date_range_filter_from", { date: date });
        if (startDate == null) {
            return [userSelectedStartDate, userSelectedStartDate];
        }
        else if (userSelectedStartDate <= startDate) {
            return [userSelectedStartDate, (endDate == null) ? userSelectedStartDate : endDate];
        }
        else if (userSelectedStartDate > startDate && (endDate == null || userSelectedStartDate >= endDate)) {
            return [userSelectedStartDate, userSelectedStartDate];
        }
        else if (userSelectedStartDate > startDate && userSelectedStartDate < endDate) {
            return [userSelectedStartDate, endDate];
        }
        else {
            return [startDate, endDate];
        }
    };
    DateRangePicker.prototype.getDateRangeByUpdatingEndDate = function (startDate, userSelectedEndDate) {
        var date = DatepickerUtils.formatDateToYMDString(userSelectedEndDate);
        if (startDate == null || userSelectedEndDate <= startDate) {
            this.updateStartDate = false;
            this.trackPageAction("date_range_filter_from", { date: date });
            return [userSelectedEndDate, userSelectedEndDate];
        }
        this.updateStartDate = true;
        this.trackPageAction("date_range_filter_to", { date: date });
        return [startDate, userSelectedEndDate];
    };
    DateRangePicker.prototype.updateInputField = function (dateRangeStringInput) {
        var textInput = this.$parentElement.find(".datepicker");
        var dateRangeString = dateRangeStringInput || "";
        this.$parentElement.toggleClass("date-pre-selected", !!dateRangeString);
        this.$parentElement.closest(".date-range-container").toggleClass("filter-selected", !!dateRangeString);
        if (textInput.length) {
            this.updatePillFiltersDatePickerButtonText(dateRangeString);
            if (this.options.isStickyDatePicker || this.options.isDynamicFilterDatePicker) {
                this.updateDateRangeLabel(dateRangeString);
            }
            textInput[0].value = dateRangeString;
        }
    };
    DateRangePicker.prototype.updatePillFiltersDatePickerButtonText = function (dateRangeString) {
        var _this = this;
        var allDateButtons = $(".dateRangePickerButtonText");
        if (allDateButtons.length < 1) {
            return;
        }
        allDateButtons
            .get()
            .forEach(function (btn, i) {
            if (dateRangeString.length) {
                $(btn).text(dateRangeString);
            }
            else {
                $(btn).text(_this.pickerInput.attr("placeholder"));
            }
        });
    };
    DateRangePicker.prototype.updateDateRangeLabel = function (dateRangeString) {
        if (dateRangeString.length) {
            $("#dateRangeValue").text(dateRangeString);
            $(".search-date-filter").removeClass("eagle-placeholder");
        }
        else {
            $("#dateRangeValue").text(this.pickerInput.attr("placeholder"));
            $(".search-date-filter").addClass("eagle-placeholder");
        }
    };
    DateRangePicker.prototype.clearRangePicker = function () {
        this.updateDatePicker(null, null, this.locale);
        this.updateStartDate = true;
    };
    DateRangePicker.prototype.trackPageAction = function (pageAction, pageProperties) {
        var servletName = ClickStream.getServlet();
        ClickStream.postClickStreamWithTrackingArguments({ servletName: servletName, pageAction: pageAction, pageProperties: pageProperties });
    };
    return DateRangePicker;
}());
export { DateRangePicker };
