export var KeyCodes;
(function (KeyCodes) {
    KeyCodes[KeyCodes["ENTER"] = 13] = "ENTER";
    KeyCodes[KeyCodes["ESCAPE"] = 27] = "ESCAPE";
    KeyCodes[KeyCodes["UP_ARROW"] = 38] = "UP_ARROW";
    KeyCodes[KeyCodes["DOWN_ARROW"] = 40] = "DOWN_ARROW";
    KeyCodes[KeyCodes["SPACE"] = 30] = "SPACE";
})(KeyCodes || (KeyCodes = {}));
export var ENTER = "Enter";
export var ARROW_DOWN = "ArrowDown";
export var ARROW_UP = "ArrowUp";
