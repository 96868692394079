var SubfolderUrlPrefix = (function () {
    function SubfolderUrlPrefix($document) {
        this.prefix = $($document).find('meta[name="subfolderUrlPrefix"]')[0].content;
    }
    SubfolderUrlPrefix.prototype.apply = function (url) {
        if (this.prefix
            && url.indexOf("/") === 0 && url.indexOf("//") !== 0
            && url.indexOf(this.prefix) !== 0) {
            return this.prefix + url;
        }
        return url;
    };
    return SubfolderUrlPrefix;
}());
export { SubfolderUrlPrefix };
