import { HTML_RESPONSE_TYPE } from "./ajax";
import { SubfolderUrlPrefix } from "../subfolderUrlPrefix";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
var NEAREST_LOCATION_URL = "/orion/nearby?";
var NearbyURL = (function () {
    function NearbyURL($window, ajaxService) {
        this.$window = $window;
        this.ajaxService = ajaxService;
        var subfolderUrlPrefix = new SubfolderUrlPrefix(this.$window.document);
        this.nearestLocationUrl = subfolderUrlPrefix.apply(NEAREST_LOCATION_URL);
    }
    NearbyURL.prototype.getNearbyUrl = function () {
        var _this = this;
        if (this.$window.navigator && this.$window.navigator.geolocation) {
            return new Observable(function (subscriber) {
                return _this.$window.navigator.geolocation.getCurrentPosition(function (position) { return _this.getNearestDestinationUrl(position).subscribe(subscriber); }, function () { return _this.getNearestDestinationUrl(null).subscribe(subscriber); });
            });
        }
        return throwError("Unable to get user's location");
    };
    NearbyURL.prototype.redirectToNearbyPage = function () {
        var _this = this;
        this.getNearbyUrl().subscribe(function (url) {
            _this.$window.location.href = url;
        });
    };
    NearbyURL.prototype.getNearestDestinationUrl = function (position) {
        if (position && position.coords) {
            var location_1 = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            };
            return this.ajaxService.postJSONSafe(this.nearestLocationUrl, location_1, HTML_RESPONSE_TYPE).pipe(map(function (response) {
                return JSON.parse(response.response).url;
            }));
        }
        return throwError("Unable to get user's location");
    };
    return NearbyURL;
}());
export { NearbyURL };
