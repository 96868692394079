import { StorageFactory } from "../storage/storageFactory";
import { sanitize } from "dompurify";
var RecentSearchesAccessor = (function () {
    function RecentSearchesAccessor($window) {
        this.localStorage = new StorageFactory().window($window).localStorage();
    }
    RecentSearchesAccessor.prototype.fetchRecentSearches = function () {
        var recentSearches = this.localStorage.getObject(RecentSearchesAccessor.RECENT_SEARCHES_KEY) || [];
        return recentSearches.map(RecentSearchesAccessor.sanitizeRecentSearchData);
    };
    RecentSearchesAccessor.prototype.removeRecentSearches = function () {
        this.localStorage.remove(RecentSearchesAccessor.RECENT_SEARCHES_KEY);
    };
    RecentSearchesAccessor.prototype.setRecentSearches = function (newRecentSearches) {
        this.localStorage.setObject(RecentSearchesAccessor.RECENT_SEARCHES_KEY, newRecentSearches);
    };
    RecentSearchesAccessor.RECENT_SEARCHES_KEY = "recentSearches";
    RecentSearchesAccessor.sanitizeRecentSearchData = function (recentSearch) {
        return {
            displayText: sanitize(recentSearch.displayText),
            url: recentSearch.url,
            destinationName: recentSearch.destinationName,
            imageUrl: recentSearch.imageUrl,
            type: recentSearch.type
        };
    };
    return RecentSearchesAccessor;
}());
export { RecentSearchesAccessor };
