import $ from "./jquery";
var SpecialChars = (function () {
    function SpecialChars() {
    }
    SpecialChars.stripSpecialChars = function (str) {
        return str.replace(/[<>'"!$^*%\\|;()+`\{\}\[\]]/gi, "");
    };
    SpecialChars.unescapeHTML = function (escapedText) {
        return $("<div/>").html(escapedText).text();
    };
    SpecialChars.removeEmojis = function (text, replaceWith) {
        if (replaceWith === void 0) { replaceWith = ""; }
        var regex = new RegExp(SpecialChars.LEGACY_EMOJI_REGEX, "g");
        try {
            regex = new RegExp(SpecialChars.MODERN_EMOJI_REGEX, "gu");
        }
        catch (e) {
        }
        return text.replace(regex, replaceWith);
    };
    SpecialChars.MODERN_EMOJI_REGEX = "\\p{Emoji_Modifier_Base}\\p{Emoji_Modifier}?|\\p{Emoji_Presentation}|\\p{Emoji}\uFE0F";
    SpecialChars.LEGACY_EMOJI_REGEX = "(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?" +
        "(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|" +
        "(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20" +
        "-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*";
    return SpecialChars;
}());
export { SpecialChars };
