var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import "../../../scss/typeahead.scss";
import { fromEvent as observableFromEvent, merge as observableMerge, combineLatest as observableCombineLatest, BehaviorSubject, Subject } from "rxjs";
import { map, throttleTime, debounceTime, distinctUntilChanged, filter, withLatestFrom, startWith, first } from "rxjs/operators";
import { AjaxService } from "../ajax";
import { ClickStream } from "../../clickstream";
import { SpecialChars } from "../specialchars";
import $ from "../jquery";
import { ARROW_DOWN, ARROW_UP, ENTER, KeyCodes } from "../keyCodes";
import { StorageFactory } from "../storage/storageFactory";
import { isDefinedAndNotNull } from "../utils/objectUtil";
export var RESULT = ".typeahead-result";
export var MIN_CHARS_TO_SEARCH = 1;
export var SEARCH_TYPE_PAGE_PROPERTY = "searchType";
export var TYPEAHEAD_SECTION_PAGE_PROPERTY = { section: "typeahead" };
var SEARCH_INPUT = ".typeahead-input";
var SEARCH_RESULTS = ".typeahead-results";
var SEARCH_MODAL = ".typeahead-modal";
var FOCUSED_FLYOUT = ".focused-flyout";
var GEO_SCOPE_ELEMENT = ".geo-scope";
var BOOTSTRAP_BUTTON_PLUGIN = ".btn-group-toggle";
var DEFAULT_GEO_SCOPE = ".default-geo-scope";
var SEARCH_BUTTON = ".typeahead-button";
var ALL_RESULTS = ".see-all-results";
var ALL_EAGLE_RESULTS = ".see-all-eagle-results";
var TYPEAHEAD_CONTAINER = ".typeahead-container";
var PRODUCT_RESULT = ".product-results";
var LOADING = ".loading";
var RESULTS_SCROLL_CONTAINER = ".category-results";
var HIGHLIGHTED_CLASS = "highlighted";
var EAGLE_HIGHLIGHTED_CLASS = "eagle-highlighted";
var SEARCH_FOCUSED_CLASS = "typeahead-focused";
var SEARCH_NON_FOCUSED_CLASS = SEARCH_FOCUSED_CLASS + "-off";
var HIGHLIGHTED_RESULT = RESULT + "." + HIGHLIGHTED_CLASS;
var EAGLE_HIGHLIGHTED_RESULT = RESULT + "." + EAGLE_HIGHLIGHTED_CLASS;
var TEXT_INPUTTED_CLASS = "text-inputted";
var SEARCH_INPUT_UPDATE_DURATION = 200;
var FLYOUT_OPEN_PAGE_ACTION = "click_search_open";
var DEFAULT_OPTIONS = {
    anchorSearchBox: false,
    anchorSearchBoxOffset: 0,
    fadeBackground: false,
    openFlyoutOnEmptySearch: true,
    toggleElement: null,
    useMouseDownTrigger: false
};
var DEFAULT_SEARCH_INFO = {
    destId: "",
    searchInput: "",
    selectedResult: null
};
var TypeaheadBase = (function () {
    function TypeaheadBase($window, parentElement, options) {
        var _this = this;
        this.textBoxObservable = new Subject();
        this.searchButtonObservable = new Subject();
        this.clearObservable = new Subject();
        this.searchRequest$ = new BehaviorSubject(["", ""]);
        this.selectedResult$ = new BehaviorSubject(["", undefined]);
        this.reopenFlyout = true;
        this.isEagleSearchTest = false;
        this.$window = $window;
        this.ajaxService = new AjaxService();
        this.localStorage = new StorageFactory().window(this.$window).localStorage();
        this.options = __assign({}, DEFAULT_OPTIONS, options);
        this.isEagleSearchTest = $(".eagle-header-search").length > 0;
        parentElement = $(parentElement);
        this.parentElement = parentElement;
        this.searchInput = parentElement.find(SEARCH_INPUT);
        this.geoScopeButtons = parentElement.find(GEO_SCOPE_ELEMENT).find(BOOTSTRAP_BUTTON_PLUGIN);
        this.searchResults = parentElement.find(SEARCH_RESULTS);
        this.focusedFlyout = parentElement.find(FOCUSED_FLYOUT);
        this.searchModal = parentElement.find(SEARCH_MODAL);
        this.typeaheadContainer = parentElement.find(TYPEAHEAD_CONTAINER);
        this.servletName = this.searchInput.attr("data-clickstream-servletname");
        this.searchInput$ = this.createSearchInputSubject();
        this.searchDestId$ = this.createDestIdSubject(this.geoScopeButtons);
        this.selectedResult$.next([this.searchInput$.value, undefined]);
        observableCombineLatest(this.searchInput$, this.searchDestId$)
            .subscribe(this.searchRequest$);
        observableFromEvent(this.searchResults, "mousemove").pipe(map(function (evt) { return $(evt.target).closest(RESULT); }), throttleTime(100))
            .subscribe(function ($result) { return _this.setHighlightedResult($result); });
        this.watchFreeTextSearchRequest();
        this.watchSearchButton(parentElement.find(SEARCH_BUTTON));
        this.watchForTextInput();
        this.removeFocusIfEnabled();
        observableFromEvent(this.searchInput, this.getOpenTrigger())
            .subscribe(function (evt) { return _this.openFocusedFlyout(); });
        observableMerge(observableFromEvent(this.searchInput, "keydown"), observableFromEvent(this.searchInput, "paste"), observableFromEvent(this.searchInput, "change"), observableFromEvent(this.searchInput, this.getOpenTrigger())).pipe(first()).subscribe(function () {
            _this.reopenFlyout = true;
        });
        var focus$ = observableFromEvent(this.searchInput, "focus");
        var upArrow$ = observableFromEvent(this.searchInput, "keydown")
            .pipe(filter(function (event) { return event.keyCode === KeyCodes.UP_ARROW; }));
        observableMerge(focus$, upArrow$).pipe(map(function (event) {
            event.preventDefault();
            return event.target;
        }), map(function (target) { return target.value; })).subscribe(function (value) {
            _this.searchInput.get(0).setSelectionRange(value.length, value.length);
        });
        this.wrapInThrottle(this.clickEventsObservable(this.$window.document), 500)
            .subscribe(function (evt) {
            var closestJQueryElement = $(evt.target).closest(TYPEAHEAD_CONTAINER);
            var clickWithinTypeahead = !!closestJQueryElement.length &&
                closestJQueryElement.get(0)
                    .isSameNode(_this.typeaheadContainer.get(0));
            var clickedOnHeader = $(evt.target).hasClass("modal-header-text") ||
                $(evt.target).hasClass("modal-header");
            _this.reopenFlyout = clickWithinTypeahead;
            if (_this.options.toggleElement) {
                if (parentElement.css("display") !== "none" && !clickWithinTypeahead) {
                    parentElement.hide();
                    evt.preventDefault();
                }
                else if ($(evt.target).closest(_this.options.toggleElement).length) {
                    parentElement.show();
                    evt.preventDefault();
                    _this.focusOnSearchBox();
                }
            }
            else if (!clickWithinTypeahead && !clickedOnHeader) {
                _this.hideFocusedFlyout();
                if (!_this.isParentElementMobile()) {
                    $(".page-content").removeClass("mask-page-content");
                }
                _this.searchModal.removeClass("modal")
                    .removeClass(SEARCH_FOCUSED_CLASS);
                _this.removeFocusIfEnabled();
            }
        });
        observableFromEvent(this.searchInput, "keydown")
            .subscribe(function (e) {
            switch (e.keyCode) {
                case KeyCodes.ENTER:
                    return _this.handleEnterKey();
                case KeyCodes.UP_ARROW:
                    return _this.handleArrow(false);
                case KeyCodes.DOWN_ARROW:
                    return _this.handleArrow(true);
            }
        });
        this.initXButton(parentElement.find(".close-x"));
    }
    TypeaheadBase.prototype.getSearchObservable = function () {
        return observableMerge(this.textBoxObservable, this.searchButtonObservable);
    };
    TypeaheadBase.prototype.getSearchButtonObservable = function () {
        return this.searchButtonObservable;
    };
    TypeaheadBase.prototype.getClearObservable = function () {
        return this.clearObservable;
    };
    TypeaheadBase.prototype.getTextBoxObservable = function () {
        return this.textBoxObservable;
    };
    TypeaheadBase.prototype.updateSearchInput = function (input) {
        this.searchInput.val(input);
        this.searchInput$.next(input);
    };
    TypeaheadBase.prototype.hideFocusedFlyout = function () {
        this.focusedFlyout.hide();
        this.reopenFlyout = false;
    };
    TypeaheadBase.prototype.focusOnSearchBox = function () {
        this.searchInput.focus();
        this.reopenFlyout = true;
    };
    TypeaheadBase.prototype.wrapInDebounce = function (stream, time) {
        return stream.pipe(debounceTime(time));
    };
    TypeaheadBase.prototype.wrapInThrottle = function (stream, time) {
        return stream.pipe(throttleTime(time));
    };
    TypeaheadBase.prototype.updateResults = function (searchTerm, html) {
        var _this = this;
        this.searchResults.html(html);
        var $productResultsContainer = this.searchResults.find(PRODUCT_RESULT);
        var placeHolderImage = $productResultsContainer.attr("place-holder-image");
        $productResultsContainer.find(RESULT).each(function (index, element) {
            var $loadingContainer = $(element).find(LOADING);
            var $image = $loadingContainer.find("img");
            var imageSource = $image.attr("image-link");
            var imageToLoad = document.createElement("img");
            var loadSuccessfully = function () {
                $image.attr("src", imageSource)
                    .removeAttr("image-link");
            };
            var loadOnError = function () {
                $image.attr("src", placeHolderImage)
                    .removeAttr("image-link");
            };
            if (imageSource) {
                imageToLoad.onload = loadSuccessfully;
                imageToLoad.onerror = loadOnError;
                imageToLoad.src = imageSource;
            }
            else {
                loadOnError();
            }
        });
        $productResultsContainer.removeAttr("place-holder-image");
        observableFromEvent(this.searchResults.find(RESULT), "click")
            .subscribe(function (e) {
            var $elem = $(e.target).closest(RESULT);
            _this.resultSelected($elem, searchTerm);
        });
        var allResultsElement;
        if (this.isEagleSearchTest) {
            allResultsElement = this.searchResults.find(ALL_EAGLE_RESULTS);
        }
        else {
            allResultsElement = this.searchResults.find(ALL_RESULTS);
        }
        observableFromEvent(allResultsElement, "click")
            .pipe(withLatestFrom(this.searchDestId$))
            .subscribe(function (_a) {
            var event = _a[0], destId = _a[1];
            return _this.notifyObservers(searchTerm, destId);
        });
    };
    TypeaheadBase.prototype.getCurrentSearchTerm = function () {
        return SpecialChars.stripSpecialChars(this.searchInput.prop("value").trim());
    };
    TypeaheadBase.prototype.notifyObservers = function (text, destId, searchButtonClicked, url) {
        if (!text.length) {
            return;
        }
        var pageProperties = TYPEAHEAD_SECTION_PAGE_PROPERTY;
        pageProperties[SEARCH_TYPE_PAGE_PROPERTY] = "FREETEXT";
    };
    TypeaheadBase.prototype.showFocusedFlyout = function () {
        this.focusedFlyout.show();
        this.reopenFlyout = true;
    };
    TypeaheadBase.prototype.createSearchInputSubject = function () {
        var _this = this;
        var getCurrentSearchInput = function () {
            return isDefinedAndNotNull(_this.searchInput.prop("value")) ?
                _this.searchInput.prop("value") : "";
        };
        var subject = new BehaviorSubject("");
        this.wrapInDebounce(observableMerge(observableFromEvent(this.searchInput, "keydown")
            .pipe(filter(function (event) {
            return !(event.key === ARROW_UP || event.key === ARROW_DOWN || event.key === ENTER && _this.isEagleSearchTest);
        })), observableFromEvent(this.searchInput, "paste")), SEARCH_INPUT_UPDATE_DURATION).pipe(map(getCurrentSearchInput), startWith(getCurrentSearchInput()), distinctUntilChanged())
            .subscribe(subject);
        return subject;
    };
    TypeaheadBase.prototype.createDestIdSubject = function ($destButton) {
        var subject = new BehaviorSubject("");
        if (!$destButton.length) {
            return subject;
        }
        var initialDestId = this.getDestId($destButton);
        observableFromEvent($destButton, "change").pipe(map(function (event) { return event.target; }), map(function (target) { return target.value; }), startWith(initialDestId))
            .subscribe(subject);
        return subject;
    };
    TypeaheadBase.prototype.getDestId = function ($destButton) {
        return $destButton.find(DEFAULT_GEO_SCOPE).val();
    };
    TypeaheadBase.prototype.initXButton = function ($xButton) {
        var _this = this;
        if (!$xButton.length) {
            return;
        }
        observableFromEvent($xButton, "click")
            .subscribe(function () {
            _this.updateSearchInput("");
            _this.clearObservable.next();
        });
        this.searchInput$.pipe(map(function (term) { return !term.length; }), distinctUntilChanged())
            .subscribe(function (isSearchInputEmpty) {
            return $xButton.toggleClass("d-none", isSearchInputEmpty);
        });
    };
    TypeaheadBase.prototype.watchSearchButton = function ($searchButton) {
        var _this = this;
        var searchInfoInteraction$ = observableMerge(this.searchRequest$.pipe(map(function (_a) {
            var searchInput = _a[0], destId = _a[1];
            return ({ destId: destId, searchInput: searchInput });
        })), this.selectedResult$.pipe(map(function (_a) {
            var searchInput = _a[0], selectedResult = _a[1];
            return ({ destId: _this.searchDestId$.value, searchInput: searchInput, selectedResult: selectedResult });
        }))).pipe(distinctUntilChanged(function (prev, curr) {
            return (prev.searchInput === curr.searchInput) && (prev.destId === curr.destId);
        }));
        observableFromEvent($searchButton, "click").pipe(withLatestFrom(searchInfoInteraction$))
            .subscribe(function (_a) {
            var event = _a[0], _b = _a[1], destId = _b.destId, searchInput = _b.searchInput, selectedResult = _b.selectedResult;
            if (!searchInput.length || _this.isEagleSearchTest) {
                if (_this.options.openFlyoutOnEmptySearch) {
                    _this.openFocusedFlyout();
                }
                _this.focusOnSearchBox();
                event.stopPropagation();
            }
            else if (selectedResult) {
                _this.resultSelected(selectedResult, searchInput);
            }
            else {
                _this.notifyObservers(searchInput, destId, true);
            }
        });
    };
    TypeaheadBase.prototype.watchForTextInput = function () {
        var _this = this;
        this.searchInput$.subscribe(function (searchInput) {
            var typeaheadContainer = _this.parentElement.find(TYPEAHEAD_CONTAINER);
            if (searchInput.length) {
                typeaheadContainer.addClass(TEXT_INPUTTED_CLASS);
            }
            else {
                typeaheadContainer.removeClass(TEXT_INPUTTED_CLASS);
            }
        });
    };
    TypeaheadBase.prototype.clickEventsObservable = function ($element) {
        return observableMerge(observableFromEvent($element, this.getOpenTrigger()), observableFromEvent($element, "touchend"));
    };
    TypeaheadBase.prototype.getOpenTrigger = function () {
        return this.options.useMouseDownTrigger
            ? 'mousedown'
            : 'click';
    };
    TypeaheadBase.prototype.openFocusedFlyout = function () {
        this.updateSearchInput(this.searchInput$.value);
        if (!this.isFocusedFlyoutOpen()) {
            if (this.options.anchorSearchBox) {
                this.anchorSearchBox();
            }
            if (this.options.fadeBackground) {
                if (!this.isEagleSearchTest) {
                    this.searchModal.addClass("modal");
                }
                if (!this.isParentElementMobile()) {
                    $(".page-content").addClass("mask-page-content");
                }
            }
            this.searchModal.addClass(SEARCH_FOCUSED_CLASS);
            this.showFocusedFlyout();
            this.enableFocusIfDisabled();
            ClickStream.postClickStream(this.servletName, FLYOUT_OPEN_PAGE_ACTION, TYPEAHEAD_SECTION_PAGE_PROPERTY);
        }
    };
    TypeaheadBase.prototype.isFocusedFlyoutOpen = function () {
        return this.focusedFlyout.is(":visible");
    };
    TypeaheadBase.prototype.isParentElementMobile = function () {
        return this.parentElement.attr('id') === "typeaheadMobileModal";
    };
    TypeaheadBase.prototype.notifyObserversWithCurrentValues = function () {
        var destId = this.geoScopeButtons.find(".active input").prop("value");
        var text = this.getCurrentSearchTerm();
        this.notifyObservers(text, destId);
    };
    TypeaheadBase.prototype.anchorSearchBox = function () {
        $("html, body").animate({
            scrollTop: this.searchInput.offset().top + this.options.anchorSearchBoxOffset
        }, 800);
    };
    TypeaheadBase.prototype.handleEnterKey = function () {
        if (this.doesHighlightedResultExist()) {
            this.resultSelected(this.getHighlightedResult(), this.getCurrentSearchTerm());
        }
        else {
            var searchId = this.isEagleSearchTest ?
                this.searchResults.find(".eagle-header-search").data("search-id")
                : this.searchResults.find(".category-results").data("search-id");
            ClickStream.postClickStreamWithTrackingArguments({
                pageAction: "search",
                pageProperties: {
                    searchTerm: this.getCurrentSearchTerm(),
                    searchMethod: "Enter",
                    searchType: "FREETEXT",
                    searchId: searchId
                },
                servletName: ClickStream.getServlet()
            });
            this.notifyObserversWithCurrentValues();
        }
    };
    TypeaheadBase.prototype.handleArrow = function (isNext) {
        var $toHighlight;
        var $highlightedResult = this.getHighlightedResult();
        if ($highlightedResult && $highlightedResult.length) {
            $toHighlight = isNext ? this.getNextResultAfterHighlightedResult($highlightedResult) :
                this.getPreviousResultBeforeHighlightedResult($highlightedResult);
        }
        else if (isNext) {
            $toHighlight = this.searchResults.find(RESULT).first();
        }
        if ($toHighlight && $toHighlight.length) {
            this.setHighlightedResult($toHighlight);
            this.scrollHighlightedResultIntoViewIfNeeded($toHighlight);
            this.setSearchInputWithHighlightedResult($toHighlight);
        }
    };
    TypeaheadBase.prototype.getNextResultAfterHighlightedResult = function ($highlightedResult) {
        var result = $highlightedResult.nextAll(RESULT).first();
        if (!this.isEagleSearchTest) {
            if (!result || !result.length) {
                result = $highlightedResult.parent().next().children().first();
                if ((!result || !result.length) &&
                    $highlightedResult.parent().next().attr("class") === "seoPages-results") {
                    result = $highlightedResult.parent().next().next().children().first();
                }
            }
        }
        return result;
    };
    TypeaheadBase.prototype.getPreviousResultBeforeHighlightedResult = function ($highlightedResult) {
        var result = $highlightedResult.prevAll(RESULT).first();
        if (!result || !result.length) {
            result = $highlightedResult.parent().prev().children().last();
            if ((!result || !result.length) &&
                $highlightedResult.parent().prev().attr("class") === "seoPages-results") {
                result = $highlightedResult.parent().prev().prev().children().last();
            }
        }
        return result;
    };
    TypeaheadBase.prototype.setSearchInputWithHighlightedResult = function ($toHighlight) {
        var highlightedText = "";
        $toHighlight.find("span")
            .each(function (index, element) {
            if (element.parentElement.className.indexOf("subtitle") === -1) {
                highlightedText = highlightedText + element.innerText;
            }
        });
        this.searchInput.val(highlightedText);
        this.selectedResult$.next([highlightedText, $toHighlight]);
    };
    TypeaheadBase.prototype.scrollHighlightedResultIntoViewIfNeeded = function ($highlighted) {
        var $results = this.searchResults.find(RESULTS_SCROLL_CONTAINER);
        var topOffset = $highlighted.offset().top - $results.offset().top;
        var bottomOffset = topOffset + $highlighted.outerHeight(true);
        if (topOffset < 0) {
            $results.scrollTop(topOffset + $results.scrollTop());
        }
        else if (bottomOffset > $results.height()) {
            $results.scrollTop(bottomOffset + $results.scrollTop() - $results.height());
        }
    };
    TypeaheadBase.prototype.getHighlightedResult = function () {
        if (this.isEagleSearchTest) {
            return this.searchResults.find(EAGLE_HIGHLIGHTED_RESULT);
        }
        else {
            return this.searchResults.find(HIGHLIGHTED_RESULT);
        }
    };
    TypeaheadBase.prototype.doesHighlightedResultExist = function () {
        var $highlightedResult = this.getHighlightedResult();
        return ($highlightedResult && $highlightedResult.length);
    };
    TypeaheadBase.prototype.setHighlightedResult = function ($element) {
        var highlightedClass;
        if (this.isEagleSearchTest) {
            highlightedClass = EAGLE_HIGHLIGHTED_CLASS;
        }
        else {
            highlightedClass = HIGHLIGHTED_CLASS;
        }
        this.searchResults.find(RESULT).removeClass(highlightedClass);
        if ($element && $element.length) {
            $element.addClass(highlightedClass);
        }
    };
    TypeaheadBase.prototype.removeFocusIfEnabled = function () {
        this.parentElement.find("." + SEARCH_FOCUSED_CLASS)
            .addClass(SEARCH_NON_FOCUSED_CLASS)
            .removeClass(SEARCH_FOCUSED_CLASS);
    };
    TypeaheadBase.prototype.enableFocusIfDisabled = function () {
        this.parentElement.find("." + SEARCH_NON_FOCUSED_CLASS)
            .addClass(SEARCH_FOCUSED_CLASS)
            .removeClass(SEARCH_NON_FOCUSED_CLASS);
    };
    return TypeaheadBase;
}());
export { TypeaheadBase };
